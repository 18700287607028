export const BI = {
  ENDPOINT: 'form-builder',
  ERROR_ENDPOINT: 'form-builder',
  SRC: 5,
  MARKET_ENDPOINT: 'market',
  MARKET_SRC: 24,
}

const TEMPLATE_PICKER_PANEL = {
  OPEN_PANEL: 910,
  SELECT_TEMPLATE: 911,
  CHOOSE_TEMPLATE: 912,
}
const MANAGE_FIELDS_PANEL = {
  OPEN_PANEL: 913,
  DELETE_FIELD: 914,
  DUPLICATE_FIELD: 932,
}

const EDIT_FIELD_PANEL = {
  OPEN_PANEL: 917,
  VALUE_UPDATED: 918,
  TOGGLE_REQUIRED_FIELD: 919,
  OPEN_CONNECT_FIELD_DROPDOWN: 920,
  SELECT_FIELD_TO_CONNECT: 921,
}

const FORM_SETTINGS_PANEL = {
  OPEN_PANEL: 922,
  VALUE_UPDATED: 923,
  SECONDS_TO_RESET_UPDATED: 925,
  SUCCESS_ACTION_TYPE_SELECTED: 926,
  SUCCESS_LINK_SELECTED: 927,
  CREATE_SUBMISSIONS_TABLE: 928,
  SUBMISSIONS_TABLE_CREATED_SUCCESSFULLY: 929,
  VIEW_SUBMISSIONS_TABLE: 931,
}

const FORM_LAYOUT_PANEL = {
  OPEN_PANEL: 924,
  CHANGE_LAYOUT: 924,
}

const ADD_FIELD_PANEL = {
  OPEN_PANEL: 915,
  SELECT_FIELD_TO_ADD: 916,
  ADD_FIELD_COMPLETE: 903,
}

const UPGRATE_ALERT_PANEL = {
  OPEN_PANEL: 934,
  ACTION_CLICK: 935,
}

const FORM_STYLE_PANEL = {
  CUSTOM_DESIGN_ACTION: 906,
}

const ALL_PANELS = {
  LOAD_FINISHED: 907,
}

const VIEWER_APP = {
  USER_CLICKS_SUBMIT: 900,
  SUBMISSION_SUCCESS: 901,
  SENT_TO_WIXDATA_SERVER_SUCCESS: 904,
  SUBMISSION_FAILURE: 902,
}

const EDITOR = {
  DELETE_SUBMISSION_BUTTON: 933,
  USER_SAVE_TEMPLATE_WITH_FORM: 936,
}

const PANELS = {
  addFormPanel: TEMPLATE_PICKER_PANEL,
  settingsPanel: FORM_SETTINGS_PANEL,
  manageFieldsPanel: MANAGE_FIELDS_PANEL,
  addFieldPanel: ADD_FIELD_PANEL,
  upgradeAlertPanel: UPGRATE_ALERT_PANEL,
  fieldSettingsPanel: EDIT_FIELD_PANEL,
  formLayoutPanel: FORM_LAYOUT_PANEL,
  formStylePanel: FORM_STYLE_PANEL,
  allPanels: ALL_PANELS,
}

const ASCEND_PP = {
  OPENED: 400,
}

export const EVENTS = {
  PANELS,
  VIEWER_APP,
  EDITOR,
  ASCEND_PP,
}

export const ORIGINS = {
  APP_MARKET: 'appmarket',
  ADD_PANEL: 'addpanel',
  SIGNUP_SETTINGS: 'signup',
}

export const PANEL_NAMES = {
  formStylePanel: 'form design',
}

export const ASCEND_APP_DEF_ID = 'ee21fe60-48c5-45e9-95f4-6ca8f9b1c9d9'
